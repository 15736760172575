<template>
  <div class="bg-secondary-dark h-full">
    <div>
      <div
        class="text-secondary-light mr-2 mt-2 cursor-pointer"
        @click="handleSidebarToggleClick"
      >
        <BIconArrowRightShort v-if="sidebarCollapsed" class="ml-auto w-4 h-4" />
        <BIconArrowLeftShort v-else class="ml-auto w-4- h-4" />
      </div>

      <div class="flex items-center px-4 pt-3 pb-6 w-auto overflow-hidden">
        <div class="flex space-x-4 overflow-hidden">
          <p
            v-if="!sidebarCollapsed"
            class="text-red font-bold my-auto truncate"
          >
            Admin Dashboard
          </p>
        </div>
      </div>
      <div class="flex flex-grow flex-col">
        <nav class="flex-1 space-y-2 px-2 justify-center" aria-label="Sidebar">
          <NuxtLink
            v-for="n in nav"
            :key="n.to"
            :to="n.to"
            class="group flex justify-between py-2 px-2 text-md"
          >
            <component
              :is="n.icon"
              :class="[
                n.isActive
                  ? 'text-lilac'
                  : 'text-secondary-lighter group-hover:text-common-white',
                sidebarCollapsed ? 'my-auto' : 'mr-3',
                'w-6 h-6',
              ]"
            />

            <span
              v-if="!sidebarCollapsed"
              :class="[
                n.isActive
                  ? 'text-lilac font-bold'
                  : 'text-secondary-lighter group-hover:text-common-white',
                'flex-1 ',
              ]"
            >{{ n.title }}</span>

            <span
              v-if="n.alerts > 0 && !sidebarCollapsed"
              :class="[
                n.isActive ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200',
                'ml-3 inline-block py-0.5 px-3 rounded-full',
              ]"
            >{{ n?.alerts }}</span>
          </NuxtLink>
        </nav>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  BIconPerson,
  BIconJustify,
  BIconArrowLeftShort,
  BIconArrowRightShort
} from 'bootstrap-icons-vue'

const route = useRoute()

// 1536 px is tailwind 2xl breakpount for responsive design
const sidebarCollapsed = ref(window.innerWidth < 1536 || false)

const nav = computed(() => {
  return [
    {
      title: 'Serviceprovider',
      to: '/admin/serviceprovider',
      icon: BIconPerson,
      isActive: route.path.includes('admin/serviceprovider'),
      alerts: 0
    },
    {
      title: 'API Logs',
      to: '/admin/logs',
      icon: BIconJustify,
      isActive: route.path.includes('admin/logs'),
      alerts: 0
    }
  ]
  //
})

function handleSidebarToggleClick () {
  sidebarCollapsed.value = !sidebarCollapsed.value
}
</script>

<style scoped></style>
